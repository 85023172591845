import { Form, Input, Tooltip } from "antd";
import React from "react";
import { DynamicInputField } from ".";

const DynamicInputText: React.FC<DynamicInputField> = (props) => {
    const {field} = props;
    const {page, tooltip_text, mandatory, placeholder, fieldOptions, type, ...otherFormItemFields} = field;

    const renderInput = () => {
      if (type === "textarea") {
          return <Input.TextArea placeholder={placeholder} allowClear rows={4} {...fieldOptions} />;
      }
      return <Input placeholder={placeholder} allowClear {...fieldOptions} />;
    };

    return (<Tooltip
      overlay={!!tooltip_text && <span dangerouslySetInnerHTML={{ __html: tooltip_text }} />}
    >
      <Form.Item
        {...otherFormItemFields}
        rules={[{ required: mandatory }]}
      >
        {renderInput()}
      </Form.Item>
    </Tooltip>
    );
  }

export default DynamicInputText;