import {
  ClearOutlined,
  DoubleRightOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RedoOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  TreeSelect,
} from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from '../../components/DynamicInput';
import { ICategoryList } from '../../data/category';
import languages from '../../data/languages.json';
import { fetchContent } from '../../data/scrapeContent';
import { fetchKeywords, IKeyword } from '../../data/scrapeResult';
import {
  fetchUrlsFromCategory,
  fetchUrlsFromLink,
} from '../../data/scrapeUrls';
import LottieLoading from '../../lottie/LottieLoading';
import { RootState } from '../../redux';
import { OMMAX_ORG_ID } from '../../redux/auth';
import {
  setContent,
  setFaqs,
  setKeywords,
  setParams,
  setScrapeUrls,
} from '../../redux/generationProcess';
import { mapCategories } from '../../utils/category';
import { asyncTimeout } from '../../utils/timeout';

/*
 * AiInterfaceCategory
 * Contains the first step of the process
 * Selection of category 1, category 2 and of the scraping URLs
 */

interface AiInterfaceCategoryProps {
  back: () => void;
  next: () => void;
  setStepsLoading: (isLoading: boolean) => void;
  categoryList: ICategoryList;
  messageApi: MessageInstance;
}

export interface AiInterfacePrimaryKeywordForm {
  primaryKeyword: string;
  targetGroup?: string;
  operationMode?: string;
  goal?: string;
  textLocation?: string;
  language?: string;
  tone?: string;
  model?: string;
  temperature?: string;
  content_type?: string;
}

export interface AiInterfaceLinkForm {
  brandSpecific: {
    selectedUrl: string;
  };
}

export interface AiInterfaceCategoryForm {
  category?: string;
  brandSpecific?: {
    selectedUrl?: string;
    faqs?: IKeyword[];
    top_brand_urls?: any[];
    top_items_urls?: any[];
    related_categories_urls?: any[];
  };
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AiInterfaceCategory: React.FC<AiInterfaceCategoryProps> = (props) => {
  const { next, setStepsLoading, categoryList, messageApi } = props;
  const [keywordForm] = Form.useForm<AiInterfacePrimaryKeywordForm>();
  const [form] = Form.useForm<AiInterfaceCategoryForm>();
  const [linkForm] = Form.useForm<AiInterfaceLinkForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showBrandSettings, setShowBrandSettings] = useState<boolean>(false);
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const settings = useSelector((state: RootState) => state.branding.settings);
  const branding = useSelector((state: RootState) => state.branding.data);
  const isMockMode = useSelector(
    (state: RootState) => state.branding.isMockMode,
  );

  //console.log(settings)

  const category = Form.useWatch('category', form);

  useEffect(() => {
    if (settings?.languages)
      keywordForm.setFieldsValue({
        language: settings?.languages[0],
        temperature: settings?.temperature?.[0],
        model:
        settings?.model?.find((m) => m.isDefault)?.id ||
        settings?.model?.[0].id,
        tone:
          settings?.tone?.find((m) => m.isDefault)?.id ||
          settings?.tone?.[0].id,
      });  
  }, [settings]);

  useEffect(() => {
    form.setFieldsValue({
      brandSpecific: {
        selectedUrl: categoryList.categories.find((c) => c.id === category)
          ?.link,
      },
    });
  }, [category]);

  // every time first level category or category list is changed, filter second level category
  const categoryOptions = useMemo(
    () => mapCategories(categoryList.categories as any),
    [categoryList],
  );

  // called when form is finished and next button clicked
  const onFinish = async () => {
    const sharedParams = await keywordForm.validateFields();
    const params = await form.validateFields();
    dispatch(setParams({ ...sharedParams, ...params }));

    setLoading(true);

    try {
      const [res] = await Promise.all([
        fetchUrlsFromCategory({ ...sharedParams, ...params }),
        asyncTimeout(3000),
      ]);
      if (!res) throw new Error('Empty response');
      dispatch(setScrapeUrls(res));
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content:
          'Fetching scrape URLs failed with the following message: ' +
          (error?.response?.status || error),
      });
      setLoading(false);
    } finally {
      setLoading(false);
      next();
    }
  };
  const onFinishLink = async () => {
    // run form validator
    const sharedParams = await keywordForm.validateFields();
    const params = await linkForm.validateFields();

    dispatch(setParams({ ...sharedParams, ...params }));
    setLoading(true);

    try {
      if (!settings?.disableLinkCrawler) {
        // @ts-ignore
        const res = await fetchUrlsFromLink({ ...sharedParams, ...params });
        if (!res) throw new Error('Empty response');
        dispatch(setScrapeUrls(res));
      }

      if (settings?.disableLinkCrawler && !settings.disableKeywordDefaults) {
        // @ts-ignore
        const res = await fetchKeywords({ ...sharedParams, ...params,
          getFaq: settings?.hasFaqCapability,
          serpNPromptFaq: settings?.faqFromSerpNPrompt,
          serpFaq: settings?.faqFromSerp
         });
        if (!res) throw new Error('Empty response');
        dispatch(setKeywords(res.keywords));
        // @ts-ignore
        if (res.faqs) dispatch(setFaqs(res.faqs));
      }

      if (settings?.hasContentFetchCapability) {
        const content = await fetchContent({ ...sharedParams, ...params });
        dispatch(setContent(content));
      }
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content:
          'Fetching scrape URLs failed with the following message: ' +
          (error?.response?.status || error),
      });
      setLoading(false);
    } finally {
      setLoading(false);
      next();
    }
  };

  const saveBrandSettings = async () => {
    const { targetGroup, goal, operationMode, textLocation } =
      keywordForm.getFieldsValue(true);
    const brandSettings = { targetGroup, goal, operationMode, textLocation };
    localStorage.setItem('BRAND_SETTINGS', JSON.stringify(brandSettings));
    messageApi.success('Brand settings successfully saved');
  };

  const restoreBrandSettings = () => {
    const settings = localStorage.getItem('BRAND_SETTINGS');
    try {
      const decode = JSON.parse(settings!);
      keywordForm.setFieldsValue(decode);
      messageApi.success('Brand settings successfully restored');
    } catch (e) {
      messageApi.error(
        'Restore brand settings failed, please check if settings were saved already on this computer',
      );
    }
  };

  // if local loading is changed, pass to parent to update icon
  useEffect(() => {
    setStepsLoading(loading);
  }, [loading]);

  return (
    <Spin
      indicator={
        <LottieLoading tip="Turning web pages into the perfect keywords – one scrape at a time!" />
      }
      spinning={loading}
    >
      <Form
        {...layout}
        form={keywordForm}
        name="ai-keyword"
        style={{ maxWidth: '100%' }}
      >
        <Divider>Content-piece parameters</Divider>
        <Tooltip
          title={
            <span>
              <b>Description:</b> the main keyword for which the text should be SEO-optimized.<br /><br />
              <b>Instructions:</b> enter one keywords that can be either a single word or a short term. Usually the primary keyword is the name of the corresponding application/ website category.
             </span>
          }
        >
          <Form.Item
            name="primaryKeyword"
            label="Primary keyword"
            rules={[{ required: true }]}
          >
            <Input placeholder="Primary keyword the content-piece will optimize for" allowClear />
          </Form.Item>
        </Tooltip>
        <Form.Item
          name="language"
          label="Language"
          rules={[{ required: true }]}
          style={{ display: isMockMode ? 'none' : undefined }}
          hidden={!settings?.languages?.length}
        >
          <Select
            showSearch
            placeholder="Select a language"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
            }
            options={settings?.languages?.map((l) => {
              // @ts-ignore
              const langData = languages[l];
              return {
                value: l,
                label: langData?.name,
              };
            })}
          />
        </Form.Item>
        <DynamicInput page='category' />
        {!!settings?.tone?.length && <Form.Item
          name="tone"
          label="Search intent"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a search intent"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
            }
            options={settings?.tone?.map((i) => ({
              value: i.id,
              label: i.name,
            }))}
          />
        </Form.Item>}

        <Divider>System settings</Divider>

        <Form.Item name="model" label="Large-language model" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="Select a LLM Model"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
            }
            options={settings?.model?.filter(i => !i.isInternalOnly || auth.auth?.['urn:zitadel:iam:user:resourceowner:id'] === OMMAX_ORG_ID)?.map((i) => ({
              value: i.id,
              label: i.name
            }))}
          />
        </Form.Item>

        {!!settings?.temperature && <Form.Item
          name="temperature"
          label="Temperature"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a temperature"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
            }
            options={settings?.temperature?.map((i) => ({
              value: i,
              label: i,
            }))}
          />
        </Form.Item>}
       
        {settings?.showPromptSettings && (
          <>
            <Divider>
              <Space>
                Brand settings
                <Tooltip title="Save brand settings">
                  <Button
                    icon={<SaveOutlined />}
                    onClick={() => saveBrandSettings()}
                  />
                </Tooltip>
                <Tooltip title="Restore brand settings">
                  <Button
                    icon={<RedoOutlined />}
                    onClick={() => restoreBrandSettings()}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    showBrandSettings
                      ? 'Hide brand settings'
                      : 'Show brand settings'
                  }
                >
                  <Button
                    icon={
                      showBrandSettings ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )
                    }
                    onClick={() => setShowBrandSettings(!showBrandSettings)}
                  />
                </Tooltip>
              </Space>
            </Divider>
            <div style={{ display: showBrandSettings ? undefined : 'none' }}>
              <Form.Item
                name="operationMode"
                label="Operation Mode"
                rules={[{ required: true }]}
                initialValue={branding?.operationMode}
              >
                <Input.TextArea
                  placeholder="Enter an operation mode statement"
                  allowClear
                  rows={3}
                />
              </Form.Item>
              <Form.Item
                name="targetGroup"
                label="Target Group"
                rules={[{ required: true }]}
                initialValue={branding?.targetGroup}
              >
                <Input.TextArea
                  placeholder="Enter a target group statement"
                  allowClear
                  rows={3}
                />
              </Form.Item>

              <Form.Item
                name="textLocation"
                label="Text location"
                rules={[{ required: true }]}
                initialValue={branding?.textLocation}
              >
                <Input.TextArea
                  placeholder="Enter a text location"
                  allowClear
                  rows={2}
                />
              </Form.Item>

              <Form.Item
                name="goal"
                label="Goal"
                rules={[{ required: true }]}
                initialValue={branding?.goal}
              >
                <Input placeholder="Enter a goal" allowClear />
              </Form.Item>
            </div>
          </>
        )}
      </Form>

      <Row gutter={32}>
        {settings?.showCategoryList && (
          <Col span={12}>
            <Divider>Select URL from list</Divider>

            <Form
              {...layout}
              form={form}
              initialValues={{
                scrapeUrls: [''],
              }}
              name="ai-category"
              onFinish={onFinish}
              style={{ maxWidth: '100%' }}
              preserve
            >
              <Form.Item name={['brandSpecific', 'selectedUrl']} hidden>
                <Input />
              </Form.Item>

              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a category level',
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select a category"
                  allowClear
                  treeDefaultExpandAll
                  treeData={categoryOptions}
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Space>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                    }}
                    icon={<ClearOutlined />}
                  >
                    Restart
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<DoubleRightOutlined />}
                  >
                    Continue
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        )}

        <Col span={settings?.showCategoryList ? 12 : 24}>
        {!settings?.noCategoryUrl ? (
          <Divider>{settings?.URLpurpose || "Page URL"}</Divider>
        ): (null)}
          
          <Form
            {...layout}
            form={linkForm}
            initialValues={{
              scrapeUrls: [''],
            }}
            name="ai-category-url"
            onFinish={onFinishLink}
            // onValuesChange={onValuesChange}
            style={{ maxWidth: '100%' }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {!settings?.noCategoryUrl ? (
              <Form.Item
                name={['brandSpecific', 'selectedUrl']}
                label={settings?.URLpurpose || "Page URL"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  allowClear
                />
              </Form.Item>
              ): (null)}
              
              <Form.Item {...tailLayout}>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<DoubleRightOutlined />}
                  >
                    Continue
                  </Button>
                </Space>
              </Form.Item>
            </Space>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
export default AiInterfaceCategory;
