import { Form, Input, Select, Tooltip } from "antd";
import React from "react";
import { DynamicInputField } from ".";

const DynamicInputSelect: React.FC<DynamicInputField> = (props) => {
    const {field} = props;
    const {page, tooltip_text, mandatory, placeholder, type, fieldOptions, ...otherFormItemFields} = field;

    return (<Tooltip
      overlay={!!tooltip_text && <span dangerouslySetInnerHTML={{ __html: tooltip_text }} />}
    >
      <Form.Item
        {...otherFormItemFields}
        rules={[{ required: mandatory }]}
      >
        <Select placeholder={placeholder} {...fieldOptions} />
      </Form.Item>
    </Tooltip>
    );
  }

export default DynamicInputSelect;