import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ProList } from '@ant-design/pro-components';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Space,
  Tooltip,
  message,
} from 'antd';
import { Key, useEffect, useState } from 'react';

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface IItem {
  name: string;
  link: string;
}

const BrandList = (props: any) => {
  const [selectModalVisible, setSelectModalVisible] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: Key[]) => setSelectedRowKeys(keys),
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [selectModalVisible]);

  const {
    label = 'Brand',
    addLabel = 'New Brand',
    importLabel = 'Import Brand',
    entity = 'brands',
    maxCount = 5,
    importData = [],
    section = '',
    hideLink = false,
    hideImport = false,
    ...otherProps
  } = props;
  
  return (
    <>
      <Form.Item label={section} style={{ marginBottom: '8px' }}>
        <Form.List {...otherProps} initialValue={hideLink ? [] : []}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {contextHolder}
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '0px auto 1fr auto',
                    gridGap: '8px',
                    marginBottom: '8px',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'active']}
                    initialValue={true}
                    valuePropName="checked"
                    hidden
                  >
                    <Checkbox>Active</Checkbox>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'Missing name' }]}
                    noStyle
                  >
                    <Input placeholder={label} />
                  </Form.Item>

                  {!hideLink && (
                    <Form.Item
                      {...restField}
                      name={[name, 'link']}
                      rules={[
                        { required: true, message: 'Missing URL' },
                        {
                          type: 'url',
                          message: 'This field must be a valid URL.',
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="URL" style={{ flex: 1 }} />
                    </Form.Item>
                  )}


            <Tooltip title="Delete">
              <Button type="text" className="dynamic-delete-button"
                  onClick={() => remove(name)}
                  icon={<DeleteOutlined />}
              >
              </Button>
            </Tooltip>

                </Space>
              ))}

              <Form.Item {...tailLayout}>
                <Space>
                  <Tooltip
                    title={
                      fields.length >= maxCount &&
                      `Not more than ${maxCount} ${entity} allowed`
                    }
                  >
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      disabled={fields.length >= maxCount}
                    >
                      {addLabel}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      fields.length >= maxCount &&
                      `Not more than ${maxCount} ${entity} allowed`
                    }
                  >
                    {!hideImport && (
                      <Button
                        style={{border: "2px dashed rgba(15,31,92)"}}
                        onClick={() => setSelectModalVisible(true)}
                        icon={<PlusOutlined />}
                        disabled={fields.length >= maxCount}
                      >
                        {importLabel}
                      </Button>
                    )}
                  </Tooltip>
                </Space>
                <Form.ErrorList errors={errors} />
                <Modal
                  open={selectModalVisible}
                  title={importLabel}
                  onCancel={() => setSelectModalVisible(false)}
                  cancelText="Close"
                  okText="Import"
                  okButtonProps={{ disabled: !selectedRowKeys.length }}
                  width={1024}
                  onOk={() => {
                    if (fields.length + selectedRowKeys.length > maxCount) {
                      messageApi.error(
                        `Not more than ${maxCount} ${entity} allowed`,
                      );
                    } else {
                      selectedRowKeys.forEach((key) =>
                        add(importData.find((d: any) => d.name === key)),
                      );
                      messageApi.success(
                        'Successfully imported ' +
                          selectedRowKeys.length +
                          ' items',
                      );
                      setSelectModalVisible(false);
                    }
                  }}
                >
                  <ProList<IItem>
                    rowKey="name"
                    dataSource={importData}
                    pagination={{
                      pageSize: 7,
                    }}
                    rowSelection={{
                      ...rowSelection,
                    }}
                    showActions="hover"
                    metas={{
                      title: {
                        dataIndex: 'name',
                        title: 'Name',
                      },
                      description: {
                        dataIndex: 'link',
                        search: false,
                      },
                    }}
                  />
                </Modal>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default BrandList;
