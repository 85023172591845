import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Modal, Space, Spin, Tooltip } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from '../../components/DynamicInput';
import { fetchKeywords } from '../../data/scrapeResult';
import LottieLoading from '../../lottie/LottieLoading';
import { RootState } from '../../redux';
import { setFaqs, setKeywords, setParams } from '../../redux/generationProcess';
import BrandList from '../formFields/BrandList';
import LinkList from '../formFields/LinkList';

/*
 * AiInterfaceCategory
 * Contains the first step of the process
 * Selection of category 1, category 2 and of the scraping URLs
 */

interface AiInterfaceKeywordProps {
  back: () => void;
  next: () => void;
  setStepsLoading: (isLoading: boolean) => void;
  messageApi: MessageInstance;
}

export interface AiInterfaceScrapeUrlForm {
  brandSpecific: {
    scrapeUrls?: string[];
  };
  primaryKeyword?: string;
  getFaq?: boolean;
  serpNPromptFaq?: boolean;
  serpFaq?: boolean;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AiInterfaceKeyword: React.FC<AiInterfaceKeywordProps> = (props) => {
  const { next, setStepsLoading, messageApi, back } = props;
  const [form] = Form.useForm<AiInterfaceScrapeUrlForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [, contextHolder] = Modal.useModal();
  const { scrapeUrls, params, content } = useSelector(
    (state: RootState) => state.generation,
  );
  const { settings, brand } = useSelector((state: RootState) => state.branding);
  const dispatch = useDispatch();

  // called when form is finished and next button clicked
  const onFinish = async () => {
    // run form validator
    const localParams = await form.validateFields();
    dispatch(setParams({ ...params, ...localParams, brandSpecific: {...params.brandSpecific, ...localParams.brandSpecific} }));

    setLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    try {
      if (!settings?.disableKeywordDefaults) {
        // @ts-ignore
        const res = await fetchKeywords({
          ...params,
          ...localParams,
          brandSpecific: {
            ...params.brandSpecific,
            ...localParams.brandSpecific,
          },
          getFaq: settings?.hasFaqCapability,
          serpNPromptFaq: settings?.faqFromSerpNPrompt,
          serpFaq: settings?.faqFromSerp,
        });
        if (!res) throw new Error('Empty response');
        dispatch(setKeywords(res.keywords));
        // @ts-ignore
        if (res.faqs) dispatch(setFaqs(res.faqs));
      }
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content:
          'Fetching categories failed with the following message: ' +
          (error?.response?.status || error),
      });
      setLoading(false);
    } finally {
      setLoading(false);
      next();
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      brandSpecific: { scrapeUrls: scrapeUrls?.scrapeUrls },
    });
  }, [scrapeUrls]);

  // if local loading is changed, pass to parent to update icon
  useEffect(() => {
    setStepsLoading(loading);
  }, [loading]);

  return (
    <Spin
      indicator={
        <LottieLoading tip="Turning web pages into the perfect keywords – one scrape at a time!" />
      }
      spinning={loading}
    >
      <Form
        {...layout}
        form={form}
        name="ai-category"
        onFinish={onFinish}
        // onValuesChange={onValuesChange}
        style={{ maxWidth: '100%' }}
      >

        {!settings?.disableGoogleLinkModule ? (
          <>
          <Divider><Tooltip title="Those URLs will be crawled to identify secondary keywords for your selection on the next screen">Crawled pages for secondary keywords</Tooltip></Divider>
          <LinkList name={['brandSpecific', 'scrapeUrls']} />
          </>
        ) : (null)}

        {brand?.id === 'central' && (
          <>
            <Divider>Internal links</Divider>

            <BrandList
              name={['brandSpecific', 'top_brand_urls']}
              section="Top Brands Links"
              label="Brand Name"
              addLabel="Add Brand Link"
              importLabel="Import Brand Links"
              importData={content.top_brand_urls}
              maxCount={4}
            />
            <BrandList
              name={['brandSpecific', 'top_items_urls']}
              section="Top Related Product Links"
              label="Product Name"
              addLabel="Add Product Link"
              importLabel="Import Product Links"
              importData={content.top_items_urls}
              maxCount={10}
            />
            <BrandList
              name={['brandSpecific', 'related_categories_urls']}
              section="Top Related L1-L3 Page Links"
              label="Page Name"
              addLabel="Add Page Link"
              importLabel="Import Page Links"
              importData={content.related_categories_urls}
              maxCount={10}
            />
            <BrandList
              name={['brandSpecific', 'top_segments']}
              section="Target Business Segments"
              label="Segment"
              addLabel="Add Segment"
              hideLink={true}
              hideImport={true}
              maxCount={10}
              entity="segments"
            />
          </>
        )}

        <DynamicInput page='keywords' />

        <Form.Item {...tailLayout}>
          <Space>
            <Button
              htmlType="button"
              onClick={() => {
                back();
              }}
              icon={<DoubleLeftOutlined />}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              icon={<DoubleRightOutlined />}
            >
              Continue
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {contextHolder}
    </Spin>
  );
};
export default AiInterfaceKeyword;
