import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Modal, Space, Spin, Tooltip, Typography } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from '../../components/DynamicInput';
import KeywordInput from '../../components/KeywordInput';
import { IKeyword } from '../../data/scrapeResult';
import LottieLoading from '../../lottie/LottieLoading';
import { RootState } from '../../redux';
import { setParams } from '../../redux/generationProcess';
import LinkList from '../formFields/LinkList';

/*
 * AiInterfaceDetails
 * Contains the second step of the process
 * Selection of all remaining parameters, e.g. model, keywords, prompt
 */

interface AiInterfaceDetailsProps {
  back: () => void;
  next: () => void;
  setStepsLoading: (isLoading: boolean) => void;
  messageApi: MessageInstance;
}

export interface AiInterfaceDetailsForm {
  brandSpecific?: {
    keywords?: IKeyword[];
  };
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AiInterfaceDetails: React.FC<AiInterfaceDetailsProps> = (props) => {
  const { next, setStepsLoading, back } = props;
  const [form] = Form.useForm<AiInterfaceDetailsForm>();
  const [loading] = useState<boolean>(false);
  const { keywords, faqs, params } = useSelector(
    (state: RootState) => state.generation,
  );
  const {brand, settings} = useSelector((state: RootState) => state.branding);
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();

  // called when form is finished and next button clicked
  const onFinish = async () => {
    // run form validator
    const localParams = await form.validateFields();
    const secondaryKeywords = localParams.brandSpecific?.keywords?.filter(i => i.category === "used")

    // check if primary keyword is included in secondary keywords
    const primaryKeywordIncludedAgain = secondaryKeywords?.map(i => i.label.toLowerCase()).includes(params?.primaryKeyword?.toLowerCase() || "") || false

    if (primaryKeywordIncludedAgain) {
      const confirm = await modal.confirm({
        title: "Primary keyword included twice",
        okText: "Continue",
        content: "The primary keyword '"+params.primaryKeyword+"' is included in the secondary keywords again. This might increase keyword density, which negatively impacts SEO score. Please make sure this is intentional."
      });
      if (!confirm) return;
    }

    // check if selected keywords include uppercase
    const uppercaseKeywords = secondaryKeywords?.filter(i => i.label.toLowerCase() !== i.label);
    if (settings?.enableUppercaseSecondaryKeywordWarning && uppercaseKeywords?.length) {
      const confirm = await modal.confirm({
        title: "Keywords include uppercase",
        okText: "Continue",
        content: "The secondary keyword(s) '"+uppercaseKeywords.map(i => i.label).join(", ")+"' include(s) uppercase characters. This might introduce grammar errors in the output, which negatively impacts SEO score. Please make sure this is intentional, e.g. for brand names."
      });
      if (!confirm) return;
    }

    dispatch(
      setParams({
        ...params,
        ...localParams,
        brandSpecific: {
          ...params.brandSpecific,
          ...localParams.brandSpecific,
        },
      }),
    );

    next();
  };

  // if local loading is changed, pass to parent to update icon
  useEffect(() => {
    setStepsLoading(loading);
  }, [loading]);

  // every time keywords are updated from parent, pass to form
  useEffect(() => {
    // @ts-ignore
    form.setFieldsValue({ brandSpecific: { keywords, faqs } });
  }, [keywords, faqs]);

  const preview =
    "Our AI works so well at SEO, even Google's algorithms ask for optimization tips!";

  return (
    <Spin indicator={<LottieLoading tip={preview} />} spinning={loading}>
      <Alert
        message="Please be aware that input and output might be stored for analytical purpose to improve our services after this step, and that every click of submit on this page gets deducted from your generation quota."
        type="info"
        showIcon
      />

      <Form
        {...layout}
        form={form}
        initialValues={{
          brandSpecific: { keywords },
        }}
        name="ai-details"
        onFinish={onFinish}
        style={{ maxWidth: 1224 }}
      >
        
        <Divider><Tooltip title="You can include keywords by selecting them and exclude keywords from content piece by deleting them">Included and excluded secondary keywords</Tooltip></Divider>
        <Typography.Text type="secondary">Secondary keywords are included in the content-piece. You can include keywords by selecting them, or ensure they are excluded with the "delete"-icon. Unselected keywords are neutral and might be included if the LLM deems it relevant, but is not instructed so. It is best practice to include and exclude 3-7 keywords each, as more will reduce quality.</Typography.Text>
        <Form.Item
          name={['brandSpecific', 'keywords']}
          label="Secondary keywords"
          rules={[{ required: false }]}
        >
          <KeywordInput maximumActiveKeywords={settings?.keywordSelectionLimit || 10} hiddenKeywords={[params.primaryKeyword!]}/>
        </Form.Item>

        {settings?.hasFaqCapability && (
          <>
            <Divider>Frequently asked questions</Divider>        
            <Typography.Text type="secondary">Questions are included in a special section of the content-piece, usually at the end. In the final copy, spelling (e.g. capitalization) is corrected independent of spelling in this input.</Typography.Text>
            <div style={{marginBottom: 10}} />
            <LinkList
              name={['brandSpecific', 'faqs']}
              label="Question"
              entity="question"
              nestedName="label"
              verifyUrl={false}
              maxCount={50}
            />
          </>
        )}

        <DynamicInput page='details' />

        <Form.Item {...tailLayout}>
          <Space>
            <Button
              htmlType="button"
              onClick={() => {
                back();
              }}
              icon={<DoubleLeftOutlined />}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              icon={<DoubleRightOutlined />}
            >
              Continue
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {contextHolder}
    </Spin>
  );
};
export default AiInterfaceDetails;
