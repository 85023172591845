import Mustache from 'mustache';
import { IBrandSettings } from '../data/brands';
import {
  newFetchResultFinal,
  newFetchResultStream,
} from '../data/generateResult';
import { Prompt, PromptStructure } from '../data/prompts';
import Postprocessor from './postprocessor';
import { InternalLink } from './qualityCheck';
import jsonLogic from "json-logic-js"

/*
 * Execution plan logic for prompts
 * An exeuction plan is a sequence of prompts including the final output, which defines how they are concatenated (see interface PromptStructure)
 * Includes helpers to check the feasability and actually run a plan
 */

interface InputObject {
  [key: string]: string;
}

interface OutputObject {
  [key: string]: string;
}

class ExecutionPlan {
  plan: PromptStructure;
  internalLinks: InternalLink[];
  input: any;
  uuid: string;
  auth: any;
  chunkResults: Map<number, string> = new Map<number, string>();
  executionResults: Map<number, string> = new Map<number, string>();
  settings: IBrandSettings | undefined = undefined;
  report: any = {
    prompt: {},
  };

  constructor(
    plan: PromptStructure,
    input: any,
    auth: any,
    settings: IBrandSettings | undefined = undefined,
    uuid: string = '',
    internalLinks: InternalLink[] = [],
  ) {
    this.plan = plan;
    this.input = input;
    this.auth = auth;
    this.settings = settings;
    this.internalLinks = internalLinks;
    this.uuid = uuid;
  }

  getParams(): any {
    return {
      ...this.input,
      executionResults: this.convertInput(
        Object.fromEntries(this.executionResults),
      ),
      prompt: this.chunkResults,
    };
  }

  convertInput(input: InputObject): OutputObject {
    return Object.keys(input).reduce((output, key) => {
      output[`res_${key}`] = input[key];
      return output;
    }, {} as OutputObject);
  }

  getOutput(): [string, string] {
    const prompts = this.convertInput(Object.fromEntries(this.chunkResults));
    const rendered = Mustache.render(this.plan.output_template, {
      prompts,
    });
    let finalOutput = rendered;
    if (this.settings?.textPostprocessor) {
      const processor = new Postprocessor();
      // @ts-ignore
      finalOutput = processor[this.settings?.textPostprocessor]?.(
        rendered,
        this.internalLinks,
      );
    }
    return [rendered, finalOutput];
  }
  
  async executePrompt(prompt: Prompt): Promise<string> {
    this.report.prompt[prompt.id] = 'started';
    const hasRetryCondition = !!prompt.retry?.condition;
    const maximumRetries = prompt.retry?.maxRetries || 3;
  
    let retries = 0;
    let output = "";

    while (retries < maximumRetries) {
      const res = (
        await newFetchResultFinal({
          ...this.getParams(),
          uuid: this.uuid,
          prompt: prompt.id,
        })
      )?.html;
      output = res;
  
      try {
        if (hasRetryCondition) {
          const parsedOutput = JSON.parse(res);
          if (jsonLogic.apply(prompt.retry!.condition, { input: parsedOutput })) {
            console.log("Retry condition failed for prompt", prompt, parsedOutput)
            if (prompt.retry?.hardCondition) {
              output = "Retry condition not succeeded"
            }
            retries++;
            continue;
          }
        }
  
        this.report.prompt[prompt.id] = 'completed';
        return res;
      } catch (e) {
        console.log("Retry condition check failed for prompt", prompt, e)
        retries++;
        if (retries >= maximumRetries) {
          this.report.prompt[prompt.id] = 'failed';
          return res; // Output as text if JSON parse fails or max retries are reached
        }
      }
    }
  
    this.report.prompt[prompt.id] = 'failed';
    return output;
  }

  async executeTemplatePrompt(
    prompt: Prompt,
    onOutputChanged: (output: [string, string]) => void | undefined,
    groupID: String | null = null,
  ): Promise<string> {
    this.report.prompt[prompt.id] = 'started';
    this.chunkResults.set(prompt.id, '');
    const response = await newFetchResultStream(
      {
        ...this.getParams(),
        uuid: this.uuid,
        prompt: prompt.id,
        groupID: groupID,
      },
      this.auth,
    );
    const decoder = new TextDecoder();
    const reader = response?.body?.getReader();

    if (!reader) {
      console.error('no reader');
    }

    while (true) {
      this.report.prompt[prompt.id] = 'chunked';
      const { done, value } = await reader!.read();
      if (done) break;
      const data = decoder.decode(value);
      this.chunkResults.set(
        prompt.id,
        (this.chunkResults.get(prompt.id) ?? '') + data,
      );
      onOutputChanged(this.getOutput());
    }
    this.report.prompt[prompt.id] = 'completed';

    return this.chunkResults.get(prompt.id) || '';
  }

  // Helper function to get prompt IDs from the output_template
  private getTemplatePromptIds(template: string): Set<number> {
    const ids = new Set<number>();
    const regex = /\{\{&?prompts\.res_(\d+)\}\}/g;
    let match;

    while ((match = regex.exec(template)) !== null) {
      ids.add(parseInt(match[1], 10));
    }

    return ids;
  }

  async executePrompts(
    onOutputChanged: (output: [string, string]) => void | undefined,
  ): Promise<[string, string]> {
    const json = this.plan;

    const promptMap = new Map<number, Prompt>();
    json.prompts.forEach((prompt) => promptMap.set(prompt.id, prompt));
    const templatePrompts = this.getTemplatePromptIds(json.output_template);

    // Topologically sort prompts based on their dependencies
    const sortedPrompts = this.topologicalSort(json.prompts, promptMap);

    // Group prompts for parallel execution
    const parallelGroups: Prompt[][] = [];
    let currentGroup: Prompt[] = [];

    // keep track of all prompts in previous groups already executed
    let executedPrompts: number[] = [];

    for (const prompt of sortedPrompts) {
      // add if no completed prompts required
      // or all completed prompts already added to previous group
      const canAddToCurrentGroup = !prompt.completed_prompts || prompt.completed_prompts?.every(
          (id) => executedPrompts.includes(id)
        )


      if (canAddToCurrentGroup) {
        // Add to current group if there are no conflicting dependencies
        currentGroup.push(prompt);
      } else {
        // If there are conflicting dependencies, start a new group
        if (currentGroup.length > 0) {
          parallelGroups.push(currentGroup);
        }
        currentGroup = [prompt];
        
        // keep array of all already executed prompts
        executedPrompts = [
          ...executedPrompts,
          ...currentGroup.map(i => i.id)
        ]
      }

    }
    // Add the last group if it's not empty
    if (currentGroup.length > 0) {
      parallelGroups.push(currentGroup);
    }

    this.report.groups = parallelGroups;

    // Execute each group of prompts in parallel
    for (const group of parallelGroups) {
      const groupID = crypto.randomUUID();
      const executions = group.map((prompt) =>
        templatePrompts.has(prompt.id)
          ? this.executeTemplatePrompt(prompt, onOutputChanged, groupID)
          : this.executePrompt(prompt),
      );

      const results = await Promise.all(executions);
      results.forEach((result, index) => {
        this.executionResults.set(group[index].id, result);
      });
    }

    return this.getOutput();
  }

  /*
  async executePrompts(onOutputChanged: (output:string) => void | undefined): Promise<string> {
    const json = this.plan;

    console.log(json)

    const promptMap = new Map<number, Prompt>();
    json.prompts.forEach((prompt) => promptMap.set(prompt.id, prompt));
    const templatePrompts = this.getTemplatePromptIds(json.output_template);

    // Topologically sort prompts based on their dependencies
    const sortedPrompts = this.topologicalSort(json.prompts, promptMap);

    console.log(sortedPrompts)
    // Execute prompts and store results
    for (const prompt of sortedPrompts) {
      console.log("for exec", prompt)
      if (!prompt.completed_prompts || prompt.completed_prompts.every((id) => this.executionResults.has(id))) {
        console.log("for exec completed", prompt)
        const result = templatePrompts.has(prompt.id)
          ? await this.executeTemplatePrompt(prompt, onOutputChanged)
          : await this.executePrompt(prompt);
        this.executionResults.set(prompt.id, result);
      }
    }

    return this.getOutput();
  }*/

  // Topological sort (Kahn's algorithm)
  topologicalSort(prompts: Prompt[], promptMap: Map<number, Prompt>): Prompt[] {
    const inDegree = new Map<number, number>();
    const result: Prompt[] = [];

    // Initialize inDegree
    prompts.forEach((prompt) => {
      inDegree.set(
        prompt.id,
        prompt.completed_prompts ? prompt.completed_prompts.length : 0,
      );
    });

    // Calculate inDegree
    prompts.forEach((prompt) => {
      prompt.completed_prompts?.forEach((depId) => {
        inDegree.set(depId, (inDegree.get(depId) || 0) + 1);
      });
    });

    // Find all nodes with no incoming edges and add them to the result array first
    inDegree.forEach((degree, id) => {
      if (degree === 0) {
        const node = promptMap.get(id);
        if (node) {
          result.push(node);
        }
      }
    });

    // Process the rest of the nodes
    prompts.forEach((prompt) => {
      if (inDegree.get(prompt.id)! > 0) {
        result.push(prompt);
      }
    });

    // Check if there was a cycle
    if (result.length !== prompts.length) {
      throw new Error(
        'Cyclic dependency detected, cannot perform topological sort',
      );
    }

    return result;
  }

  checkFeasibility(): void {
    const promptMap = new Map<number, Prompt>();
    const { output_template } = this.plan;
    this.plan.prompts.forEach((prompt) => promptMap.set(prompt.id, prompt));

    // Function to check for cyclic dependencies
    function checkCyclicDependencies(
      promptId: number,
      visited: Set<number>,
      stack: Set<number>,
    ): boolean {
      if (!visited.has(promptId)) {
        visited.add(promptId);
        stack.add(promptId);

        const prompt = promptMap.get(promptId);
        if (prompt && prompt.completed_prompts) {
          for (const id of prompt.completed_prompts) {
            if (
              !visited.has(id) &&
              checkCyclicDependencies(id, visited, stack)
            ) {
              return true;
            } else if (stack.has(id)) {
              return true;
            }
          }
        }
      }

      stack.delete(promptId);
      return false;
    }

    // Function to check if all referenced prompts in output_template are existing and executed
    function checkTemplateReferences(): boolean {
      const templateRegex = /\{\{prompts\[(\d+)\]\}\}/g;
      let match;
      while ((match = templateRegex.exec(output_template)) !== null) {
        const promptId = parseInt(match[1]);
        if (!promptMap.has(promptId) || !isExecuted(promptId)) {
          return false;
        }
      }
      return true;
    }

    // Function to check if a prompt is executed (i.e., all its dependencies are met)
    function isExecuted(promptId: number): boolean {
      const prompt = promptMap.get(promptId);
      if (prompt && prompt.completed_prompts) {
        return prompt.completed_prompts.every(
          (id) => promptMap.has(id) && isExecuted(id),
        );
      }
      return true;
    }

    // Check for cyclic dependencies
    const visited = new Set<number>();
    const stack = new Set<number>();
    for (const prompt of this.plan.prompts) {
      if (checkCyclicDependencies(prompt.id, visited, stack)) {
        throw new Error(
          `Cyclic dependency detected involving prompt ${prompt.id}`,
        );
      }
    }

    // Check for output_template references
    if (!checkTemplateReferences()) {
      throw new Error(`Invalid references in output_template`);
    }
  }
}

export default ExecutionPlan;
