import { AlertProps } from "antd";
import request from '../utils/request';
import { PromptStructure } from './prompts';
import { ILLM, ITone } from './staticData';

export interface IBrand {
  // generic stuff
  name: string;
  label: string;
  logoUrl?: string;
  logoStyle?: React.CSSProperties;

  // prompt defaults
  targetGroup?: string;
  operationMode?: string;
  goal?: string;
  textLocation?: string;
}

// this settings allow to override brand data (e.g. theming)
export const BrandList: IBrand[] = [
  {
    name: 'westwing',
    label: 'Westwing',
    logoUrl: process.env.PUBLIC_URL + '/brands/westwing.png',
    logoStyle: {
      width: 120,
      backgroundSize: '100%',
      backgroundColor: 'white',
      marginRight: 10,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  {
    name: 'tyrolit',
    label: 'Tyrolit',
    logoUrl: process.env.PUBLIC_URL + '/brands/tyrolit.png',
    logoStyle: {
      width: 120,
      backgroundSize: '100%',
      backgroundColor: 'white',
      marginRight: 10,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  {
    name: 'central',
    label: 'Central',
    logoUrl: process.env.PUBLIC_URL + '/brands/central.png',
    logoStyle: {
      width: 120,
      backgroundSize: '100%',
      backgroundColor: 'white',
      marginRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  {
    name: 'nutricia',
    label: 'Nutricia',
    logoUrl: process.env.PUBLIC_URL + '/brands/nutricia.png',
    logoStyle: {
      width: 120,
      backgroundSize: '100%',
      backgroundColor: 'white',
      marginRight: 10,
    }
  },
  {
    name: 'agnostic',
    label: 'SaaS (Classic)',
    operationMode:
      'Du bist ein Senior SEO Content Creator und du willst einen für die organische Suche optimierten Text für die folgende Produktkategorienseite für den Online-Shop von Westwing schreiben',
    targetGroup:
      'Die Zielgruppe sind größtenteils Frauen, die ihr Zuhause stilvoll und exklusiv einrichten wollen und bereit sind ihr ihr Geld zu investieren',
    goal: 'Conversions im Westwing Onlineshop zu steigern',
    textLocation:
      "Der Text soll auf der Produktkategorienseite in Westwing's online E-Commerce Shop veröffentlicht werden",
  },
  {
    name: 'PUIG_PLP',
    label: 'PUIG (PLP)'
  },
  {
    name: 'PUIG_Blog',
    label: 'PUIG (Blog)'
  },
  {
    name: 'saas_plp',
    label: 'SaaS (PLP)'
  },
  {
    name: 'saas_blog',
    label: 'SaaS (Blog Content)'
  },
];

export default BrandList;

export const DEFAULT_BRAND = 'westwing';

export interface IBrandSettingsRes {
  settings: IBrandSettings;
  user: IUserSettings;
}

interface QualityCheckDefinitionItem {
  module: string;
  [option: string]: string | number | boolean;
}

interface RegenerateItem {
  id: number;
  name: string;
}

export interface InputField {
  name: string,
  label: string,
  placeholder?: string,
  mandatory?: boolean,
  tooltip_text: string,
  importData?: string;
  type: string,
  page?: string;
}

export interface IUserSettings {
  is_brand_admin: boolean;
  is_restricted_admin: boolean;
  is_global_admin: boolean;
}

export interface IBrandRecord {
  timestamp_updated: string;
  timestamp_created: string;
  public_settings: IBrandSettings;
  label: string;
  id: string;
  _v: number;
}

export interface IBrandSettings {
  executionPlan: PromptStructure;
  languages: string[];
  keywordSelectionLimit?: number;
  hasFaqCapability?: boolean;
  noCategoryUrl?: boolean;
  faqFromSerpNPrompt?: boolean,
  faqFromSerp?: boolean;
  hasContentFetchCapability?: boolean;
  hasPpmCapability?: boolean;
  showInputOnResultPage?: boolean;
  disableGoogleLinkModule?: boolean;
  showCategoryList?: boolean;
  showUrlInput?: boolean;
  enableUppercaseSecondaryKeywordWarning?: boolean;
  showPromptSettings?: boolean;
  disableLinkCrawler?: boolean;
  disableKeywordDefaults?: boolean;
  copyOutputHandler?: string;
  textPostprocessor?: string;
  qualityCheck?: QualityCheckDefinitionItem[];
  regenerate?: RegenerateItem[];
  brand_specific_input_fields?: InputField[];
  URLpurpose?: string;
  temperature?: string[];
  tone?: ITone[];
  model?: ILLM[];
  alert?: AlertProps;
  tonality?: string;
}

export interface BrandListItem {
  id: string;
  label: string;
  isLegacy?: boolean;
}

export const fetchBrandSettings = async (brand: string) => {
  const res = await request<IBrandSettingsRes>('/settings', {
    method: 'get',
    data: {},
    headers: {
      'X-Organization': brand,
    },
  });
  return res;
};

export const fetchAvailableBrands = async () => {
  const res = await request<BrandListItem[]>('/getAvailableBrands', {
    method: 'get',
    data: {},
    headers: {
      'X-Organization': '',
    },
  });
  return res;
};

/* manage brands */