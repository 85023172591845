import React from "react";
import { useSelector } from "react-redux";
import { DynamicInputField } from ".";
import BrandList from "../../pages/formFields/BrandList";
import { RootState } from "../../redux";


const DynamicInputBrandList: React.FC<DynamicInputField> = (props) => {
    const {field} = props;
    const {page, type, importData, ...otherFormItemFields} = field;
    const { content } = useSelector(
      (state: RootState) => state.generation,
    );

    return (
      <BrandList
        importData={content?.[importData as any] || []}
        {...otherFormItemFields}
      />
    );
  }

export default DynamicInputBrandList;